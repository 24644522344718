import { Button, Checkbox, Col, Form, Input, Modal, Row } from "antd";
import Modelimage1Img from "../../assets/images/model-img.png";
import lang from "../../helper/langHelper";
import { useState, Link, useContext, useEffect } from "react";
import LoginlogoImg from "../../assets/images/loginlogomain.png";
import { PhoneNumberField } from "../../components/InputField";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import apiPath from "../../constants/apiPath";
import { useNavigate } from "react-router";
import { max } from "lodash";
import { AuthContext, useAuthContext } from "../../context/AuthContext";
import GoogleLogin from "./GoogleSignUp";
import { showError, showPosition } from "../../helper/functions";
import { Spinner } from "react-bootstrap";
import { InputOTP } from "antd-input-otp";
import useFirebase from "../../hooks/useFirebase";

const SignUp = ({ show, hide, login  }) => {
  const [loading, setLoading] = useState(false);
  const [mobileNumber, setMobileNumber] = useState({
    mobile_number: "",
    country_code: "",
  });
  const { setIsLoggedIn, setUserProfile } = useContext(AuthContext);
  const [latLong, setLatLong] = useState({ lat: "",  long: "", });
  const { request } = useRequest();
  const navigate = useNavigate();
  const { fcmToken } = useFirebase();
  const [form] = Form.useForm();
  const [otpForm] = Form.useForm();
  const [verifyLoading, setVerifyLoading] = useState(false);
  const {userProfile} = useAuthContext()
  const [resendTimer, setResendTimer] = useState(0);
  const [loading2, setLoading2] = useState(false);
  const [formData, setFormData] = useState({});
  const [otpModalShow, setOtpModalShow] = useState(false);



  useEffect(() => {
    let latitude, longitude;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        latitude =  position.coords.latitude;
        longitude = position.coords.longitude;
        setLatLong({ lat: latitude, long: longitude });
      }, showError);
    } else {
      // ShowToast("Geolocation is not supported by this browser.", Severty.ERROR);
    }
  }, []);


  const onSubmit = (values)=>{
    console.log(values,"onSubmit")
   
    handleResendOtp(values)
    setFormData(values)
  }


  const handleResendOtp = (value) => {
    if (resendTimer > 0) return
    setLoading2(true);
    let payload = {};
    payload.email = value?.email ?  value?.email : formData?.email ;
    payload.mobile_number = mobileNumber.mobile_number ;
    payload.country_code = mobileNumber.country_code ;
    payload.use_for = "SignUp";
    request({
      url: apiPath.sendOtp,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading2(false);
        if (data.status) {        
          ShowToast(data.message, Severty.SUCCESS)
          setOtpModalShow(true)
          setResendTimer(60)
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        setLoading2(false);
        ShowToast(error?.response?.data?.message, Severty.ERROR);
      },
    });
  };


  const handleVerifyOTP = (values) => {
    setVerifyLoading(true);
       let combinedOtp = values.otp; 
    if (combinedOtp.length !== 4) { ShowToast(lang("Please enter the complete OTP"), Severty.ERROR); return; }
       combinedOtp = values.otp ? values.otp.join("") : "";


    let payload = {};
    payload.email = formData?.email ? formData?.email : "" ;
    payload.otp = combinedOtp;
    payload.mobile_number = mobileNumber.mobile_number ;
    payload.country_code = mobileNumber.country_code ;
    payload.use_for = "SignUp";
    payload.device_type = "web"
    request({
      url: apiPath.verifyOtp,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        if (data.status) {
          setVerifyLoading(false);         
          ShowToast(data.message, Severty.SUCCESS);                
          signUp()
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        setVerifyLoading(false);
        ShowToast(error?.response?.data?.message, Severty.ERROR);
      },
    });
  };

  const signUp = (values) => {
    setLoading(true);
    let payload = { ...formData, ...mobileNumber ,  device_token: fcmToken };
    payload.sign_up_device = "Web";
    console.log("payload\n", payload, "\npayload");
    request({
      url: apiPath.signUp,
      method: "POST",
      data: { ...payload, latitude: latLong.lat, longitude: latLong.long },
      onSuccess: (data) => {
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          setIsLoggedIn(true);
          setUserProfile(userProfile);
          localStorage.setItem("token", data.token);
          localStorage.setItem("userProfile", JSON.stringify(data.user));
          setTimeout(() => navigate("/home"), 200);
          setLoading(false);
          setOtpModalShow(false)
          hide()
        } else {
          setLoading(false);
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error?.response?.data?.message, Severty.ERROR);
      },
    });
  };

  useEffect(() => {
    let timer;
    if (resendTimer > 0) {
        timer = setTimeout(() => {
            setResendTimer(resendTimer - 1);
        }, 1000);
    }

    return () => {clearTimeout(timer);  };
}, [resendTimer]);


const handleMobileNumberChange = (value, data, type) => {
  let country_code = data?.dialCode;
  setMobileNumber({
    country_code: country_code,
    mobile_number: value.slice(data?.dialCode?.length),
  });
};

  return (
    <>
     {show && !otpModalShow && 
              <Modal
        width={1249}
        title="Basic Modal"
        open={show}
        onOk={"handleOk"}
        onCancel={hide}
        className="main-model_imgg1 signin-modeel"
      >
        <div className="model-form-main-1">
          <div className="model-img">
            <img src={Modelimage1Img} alt="#" />
          </div>
          <div>
            <Form
              form={form}
              onFinish={(e) => onSubmit(e)}
              layout="vertical"
              className="row-col signup5-model"
            >
              <div className="login-name">
                <img src={LoginlogoImg} /> <span>{lang("Sign Up")}</span>
              </div>
              <Row gutter={24}>
                <Col span={24} md={24}>
                  <Form.Item
                    className="username"
                    name="name"
                    rules={[
                      {
                        max: 250,
                        message: lang(
                          "Name should contain more then 250 characters!"
                        ),
                      },
                      {
                        required: true,
                        message: lang("Please Enter  Name"),
                      },
                    ]}
                  >
                    <Input placeholder={lang("Full Name")} />
                  </Form.Item>
                </Col>
                {/* <Col span={24} md={12}>
                <Form.Item
                  className="username"
                  // label={lang("Last Name")}
                  name="last_name"
                  rules={[
                    {
                      max: 250,
                      message: lang(
                        "Name should contain more then 250 characters!",
                      ),
                    },
                    {
                      required: true,
                      message: lang("Please Enter  Name"),
                    },
                  ]}
                >
                  <Input placeholder={lang("Last Name")} />
                </Form.Item>
              </Col> */}
              </Row>

              <Form.Item
                className="username"
                // label={lang("Email Address")}
                name="email"
                rules={[
                  {
                    type: "email",
                    message: lang("Please enter a valid email address!"),
                  },
                  {
                    max: 255,
                    message: lang(
                      "Email address not more then 255 characters!"
                    ),
                  },
                  {
                    required: true,
                    message: lang("Please enter email!"),
                  },
                ]}
              >
                <Input placeholder={lang("Email address")} />
              </Form.Item>

              <PhoneNumberField
                label={""}
                name="mobile"
                placeholder={lang("Enter Phone Number")}
                cover={{ md: 24 }}
                colProps={{ sm: 24, span: 24 }}
                className="username"
                onChange={handleMobileNumberChange}
                number={mobileNumber?.mobile_number}
              />

              <Form.Item
                // label={lang("New Password")}
                name="password"
                rules={[
                  {
                    required: true,
                    message: lang("Please enter your new password!"),
                  },
                  {
                    min: 6,
                    message: lang("Maximum 6 characters are required"),
                  },
                  // {
                  //   pattern: new RegExp(
                  //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).{8,}$/,
                  //   ),
                  //   message: lang(
                  //     "New password atleast contain 8 characters, atleast contain one captital letter, atleast contain one small letter, atleast contain one digit, atleast contain one special character",
                  //   ),
                  // },
                ]}
              >
                <Input.Password placeholder={lang("Enter New Password")} />
              </Form.Item>
              <Form.Item
                // label={lang("Confirm New Password")}
                name="confirm_new_password"
                dependencies={["password"]}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: lang("Please enter the confirm password!"),
                  },
                  {
                    min: 6,
                    message: lang("Maximum 6 characters are required"),
                  },
                  // {
                  //   pattern: new RegExp(
                  //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).{8,}$/,
                  //   ),
                  //   message:
                  //     "Confirm password atleast contain 8 characters, atleast contain one captital letter, atleast contain one small letter, atleast contain one digit, atleast contain one special character",
                  // },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          lang("Password that you entered doesn't match!")
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password placeholder={lang("Enter Confirm Password")} />
              </Form.Item>

              <Form.Item>
                <Button
                  className="float-right"
                  type="primary"
                  htmlType="submit"
                  // loading={loading}
                >
                  {lang("Submit")}
                </Button>
              </Form.Item>

              <Form.Item>
                <div className="already-have">
                  <span>
                    {lang("Already have an account?")} <a onClick={login}>{lang("Log in")}</a>
                  </span>
                </div>
              </Form.Item>
            </Form>
            <div className="second-signe-btm">
              <GoogleLogin />
            </div>
          </div>
        </div>
              </Modal>
      }

   {show && otpModalShow &&
               <Modal
    width={1249}
    visible={show}
    okText={lang("Verify")}
    onCancel={() => {
      hide();
      setOtpModalShow(false)
      setResendTimer(0)
      form.resetFields()
    }}
    footer={false}
    className="tab_modal main-otp-model"
    okButtonProps={{
      form: "verify-otp",
      htmlType: "submit",
      loading: verifyLoading,
    }}
  >
    <div className="model-form-main-1">
      <div className="model-img">
        <img src={Modelimage1Img} alt="#" />
      </div>
      <Form
        id="verify-otp"
        form={otpForm}
        onFinish={handleVerifyOTP}
        autoComplete="off"
        layout="vertical"
      >
        <h4 className="modal_title_cls">
          <img src={LoginlogoImg} /> {lang("Verify OTP")}
        </h4>

        <Form.Item
          name="otp"
          rules={[
            { required: true, message: lang("Please enter the OTP") },
            
          ]}
        >
          <InputOTP
            className="otp-of-type"
            autoSubmit={false}
            length={4}
            inputType="numeric"
          />
        </Form.Item>
       
        <Form.Item>
          <Button
            className="float-right"
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            {lang("Verify")}
          </Button>
        </Form.Item>
        <div className="otherLink text-center ">{lang("Didn't Receive OTP")}?
         <button disabled={resendTimer} style={{ background: "none", border: "none", color: resendTimer ? "GrayText" : "#0AA9DD" }} onClick={handleResendOtp} className="textBtn">{loading2 ? <Spinner animation="border" size="sm" /> : lang("Resend")} </button>
         <span style={{ float: "right" }}>{resendTimer ? (resendTimer <= 9 ? "0" + resendTimer + "sec" : resendTimer + "sec") : ("")}</span>
        </div>
      </Form>
    </div>
   </Modal>
      }
    </>
  );
};

export default SignUp;
